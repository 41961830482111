.container {
  margin: auto;
  width: 330px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.container>p {
  font-size: 2rem;
}

.child1,
.child2 {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.child1 #day,
.child2 #dayS {
  width: 30%;
  height: 50px;
  font-size: 1.5rem;
  text-align: center;
}

.child1 #month,
.child2 #monthS {
  width: 30%;
  height: 50px;
  font-size: 1.3rem;
}


.child1 #year,
.child2 #yearS {
  width: 30%;
  height: 44px;
  font-size: 1.5rem;
  text-align: center;
}

.btn {
  width: 100%;
  margin-top: 20px;
}

/* CSS */
.button-1 {
  width: 100%;
  background-color: #EA4C89;
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 15px;
  font-weight: 500;
  height: 40px;
  line-height: 20px;
  list-style: none;
  margin: 0;
  outline: none;
  padding: 10px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: color 100ms;
  vertical-align: baseline;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-1:hover,
.button-1:focus {
  background-color: #F082AC;
}